.loader {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  background-color: #2b2b29;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-img {
  max-width: 100%;
  height: 36%;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  animation: pic 2s infinite;
}

@keyframes pic {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
